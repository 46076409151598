.footer-mobile {
  max-height: 329px;
  width: 100%;
  border-top: 1px solid #ddd;
}
.footer-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 900;
  height: 40px;
}
.footer-div-img {
  width: 30%;
  display: flex;
  justify-content: center;
}
.footer-div-img img {
  max-width: 57px;
  max-height: 57px;
}
.footer-main {
  display: flex;
  width: 100%;
  align-items: center;
}
.footer-div-span {
  width: 40%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footer-div-span span {
  font-size: 16px;
  font-weight: 600;
  color: #0c14cc;
}
.footer-img-icons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-bottom: 32px;
}
.footer-img-icons img {
  max-width: 32px;
  max-height: 32px;
}

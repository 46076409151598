.image-gallery-thumbnail {
  width: 120px;
}

.image-gallery-thumbnail img {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.image-gallery-slide .image-gallery-image {
  width: auto;
  height: 300px;
  object-fit: contain;
  object-position: center center;
  padding-bottom: 1rem;
}

.botoesInicio .btn {
  width: 120px;
  height: 30px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  /* background-image: url(../../images/logoCirculo.png); */
  /* background-color: rgb(56, 56, 167); */
  /* background-size: 100px 100px; */
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

/* .botoesInicio .btn:hover {
  background-color: rgb(0, 13, 133);
} */

.botoesInicio {
  display: flex;
  padding-right: 20%;
  padding-left: 20%;
}

.contato {
  /* position: absolute; */
  margin-left: 75%;
  margin-top: 5%;
  height: 10%;
  width: 10%;
  color: rgb(0, 13, 133);
  font-weight: bold;
  font-size: 20px;
  background-color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.contato:hover {
  -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
}

.imagemProd {
  margin-top: 2%;
  margin-left: 10%;
  height: 80%;
  width: 80%;
  background-size: cover;
}

.prodFundo {
  position: relative;
  background-color: #fff;
  width: 70%;
  height: 40%;
}

.descricao {
  background-color: #ececec;
  width: 10%;
  height: 3%;
  margin-left: 15%;
  margin-top: 1%;
  color: black;
  border-top: solid rgb(0, 17, 172);
  font-size: 25px;
  align-items: center;
  text-align: center;
}

.caixaDescricao {
  position: relative;
  padding-bottom: 1rem;
}

.descricaoTxt {
  margin-left: 0%;
  font-size: 25px;
  margin-top: 10%;
}

@media only screen and (max-width: 1600px) {
  .descricao {
    margin-top: 1%;
  }
  .descricaoTxt {
    font-size: 20px;
    margin-left: 0%;
  }
}

.descricaoText {
  border: solid #ececec;
  height: 25%;
  width: 70%;
  color: black;
  margin-left: 15%;
  background: white;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  padding: 0px 12px;
}

.descricaoTxt2 {
  margin-left: 1%;
}

.lancamentos {
  background-color: #ececec;
  margin-top: 2%;
  height: 10%;
}

.detalhes {
  margin-left: 4%;
  margin-top: 1%;
  margin-bottom: 0%;
  font-size: 30px;
  font-weight: bold;
  display: block;
}

.titulos {
  margin-left: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 20px;
  display: block;
}

.image-slide {
  appearance: none;
  z-index: 100;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  background: rgb(0, 17, 172);
  border-radius: 20%;
  cursor: pointer;
}

.image-slide:last-of-type {
  margin: 0;
}

.image-slide:focus {
  outline: none;
}

.image-slide:checked {
  background: rgb(0, 0, 0);
}

.image-slide + * {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform ease-in-out 400ms;
  border-radius: 5px;
  overflow: hidden;
  background: transparent;
}

.image-slide + *[aria-label]:before {
  content: attr(aria-label);
  display: block;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  font-size: 1.5rem;
  color: white;
  z-index: 1;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
}

section {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-slide:checked + * {
  transform: translateX(0);
  z-index: 1;
}

/* 
	just to be safe we hide every element that comes after
	each slide and that's not an input of type radio
*/

.image-slide + * + *:not(.image-slide[type="radio"]) {
  display: none;
}

/* ARROWS */

.image-slide:before {
  display: none !important;
  content: "";
  position: absolute;
  top: 50%;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  background: orange;
  border-radius: 50%;
}

.image-slide:after {
  display: none !important;
  content: "";
  position: absolute;
  top: calc(50% + 1rem);
  left: 2rem;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom: 1px solid #262626;
  border-left: 1px solid #262626;
  transform: translate(-25%, -50%) rotate(45deg);
}

.image-slide:checked:before,
.image-slide:checked:after {
  display: none;
}

.image-slide:checked + * + .image-slide:before {
  left: calc(100% - 3rem);
}

.image-slide:checked + * + .image-slide:after {
  left: calc(100% - 2rem);
  transform: translate(-75%, -50%) rotate(-135deg);
}

.image-slide:checked + * + .image-slide + * ~ .image-slide:before,
.image-slide:checked + * + .image-slide + * ~ .image-slide:after {
  display: none;
}

.image-slide:last-of-type:before {
  background: green;
}

.slider2 {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.slider2 figure div {
  float: left;
  width: 20%;
  background-color: #ececec;
}

.slider2 figure img {
  margin-top: 0%;
  width: 100%;
  height: 60%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  background-color: rgba(0, 0, 0, 0.3);
}

.slider2 figure {
  position: relative;
  width: 500%;
  margin: 0;
  animation: animate 70s infinite;
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes animate {
  0% {
    left: 0%;
  }
  10% {
    left: 0%;
  }
  12% {
    left: -100%;
  }
  22% {
    left: -100%;
  }
  24% {
    left: -200%;
  }
  34% {
    left: -200%;
  }
  36% {
    left: -300%;
  }
  46% {
    left: -300%;
  }
  48% {
    left: -400%;
  }
  58% {
    left: -400%;
  }
  60% {
    left: -300%;
  }
  70% {
    left: -300%;
  }
  72% {
    left: -200%;
  }
  82% {
    left: -200%;
  }
  84% {
    left: -100%;
  }
  94% {
    left: -100%;
  }
  96% {
    left: 0%;
  }
}

.imaTitulo {
  display: flex;
  width: 70%;
  margin: 5% 15% 0;
  background: white;
  box-sizing: border-box;
  border: solid #ececec;
}

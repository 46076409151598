.botoesInicio .btn {
  width: 110px;
  height: 30px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  /* background-image: url(../../images/logoCirculo.png); */
  /* background-color: rgb(56, 56, 167); */
  /* background-size: 100px 100px; */
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}
 .btn-imagem {
  width: 180px;
  height: auto;
  position: absolute;
  left: 70px;
  top: -80%;
  backgroundColor: white;
  borderRadius: 100px;
 }

 

 


/* .botoesInicio .btn:hover {
  background-color: rgb(0, 13, 133);
} */

.botoesInicio {
  display: flex;
  padding-right: 20%;
  padding-left: 20%;
}

.textoEmpresa {
  margin-top: 7%;
  margin-bottom: 5%;
  font-size: 30px;
  margin-left: 5%;
  text-align: justify;
  margin-right: 60%;
  align-items: center;
}

.contadores {
  position: absolute;
  display: inline-block;
  margin-left: 55%;
  margin-right: 0%;
  margin-top: 5%;
  align-items: center;
  text-align: center;
}

.four {
  display: inline-block;
  align-items: center;
  text-align: center;
  margin-top: 5%;
}

.counter-box {
  background: #ececec;
  padding: 0px 60px 10px;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.counter-box p {
  margin: 5% 0 0;
  padding: 0;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  text-align: center;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15%;
  color: rgb(0, 0, 0);
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.counter {
  display: flex;
  font-size: 32px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  line-height: 28px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  margin-top: 5%;
}

.teste9 {
  display: inline-block;
  margin-top: 20%;
}

.counter-box.colored {
  background: #ececec;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: rgb(0, 0, 0);
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.junto {
  display: inline-block;
  background-color: blue;
}


@media screen and (max-width: 768px) {

  .btn{
    display: none;
  }
  .counter-box.colored{
    background-color: #fff;
  }
  .counter-box{
    background-color: #fff;
  }
  .slideshows{
    grid-area: slide;
    position: absolute;
    display: flex;
    object-position: center;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    left: 10%;
    right: 10%;
  }
  .four col-md-3{
    display: flex;
    flex-direction: column;
  }
  .icones{
    display: flex;
    flex-direction: column;
  }
  .selos{
    display: flex;
    flex-direction: column;
    width: min(80%,200px);
    height: min(80%,200px);
    margin-left: 0%;
  }
  .btn-imagem{
    left: 50%;
    transform: translateX(-50%);
  }
  .legendaTitle{
   display: none;
 }
 .wrapper{
   font-size: 100px;
   grid-area: slide;
   object-position: center;
   background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-left: 0%;
  margin-top: 10%;
}
 
 .icones{
   display: flex;
   flex-direction: column;
 }
  .contadores{
    width: 100%;
    position: relative;
    object-position: center;
    font-size: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    grid-area: contadores;
  }
  .textoEmpresa{
    margin-right: auto;
    width: 90%;
    margin-top: 80%;
   margin-bottom: 5%;
   font-size: 25px;
   margin-left: auto;
   text-align: justify;
   margin-right: auto;
   align-items: center;
   grid-area: texto;
  }
  .btn-imagem {
   width: 110px;
   height: auto;
   position:relative;
   left: 50%;
   top: -80%;
   backgroundColor: white;
   borderRadius: 80px;
  }
  .Header{
    width: 100%;
    height: 50vh;
    object-position: center;
  }
  .TitleHome{
    display: none;
  }
}
@media screen and (max-width: 400px){
  .selos{
    right: 1px;
    display: flex;
    flex-direction: column;
    width: min(80%,200px);
    height: min(80%,200px);
    margin-left: 0%;
    background-color: blue;
  }
  .slideshows{
    grid-area: slide;
    position: absolute;
    display: flex;
    object-position: center;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    left: 10%;
    right: 10%;
  } 
  .textoEmpresa{
    font-size: 22px;
  }
}
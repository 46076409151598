.app-header-mobile {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  gap: 15%;
  background-color: #fff;
}
.btn-sidebar {
  width: 25px;
  height: 22px;
  cursor: pointer;
}
.app-header-mobile img {
  max-width: 51px;
  max-height: 51px;
}
.header-bandeiras {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-bandeiras img {
  max-width: 21px;
  max-height: 21px;
  cursor: pointer;
}
.sideBar-body {
  min-width: 50%;
  max-width: 300px;
  position: fixed;
  left: -400px;
  top: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  transition: all ease 0.5s;
  z-index: 999;
}
.sidebar-span-ctn {
  display: flex;
  justify-content: flex-start;
  margin-top: 70px;
  gap: 26px;
  align-items: center;
  flex-direction: column;
  height: 50%;
}

.sidebar-span-ctn button {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}

.sidebar-span-ctn span {
  font-size: 13px;
  font-weight: 600;
}
.sideBar-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 30px;
}

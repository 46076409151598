@keyframes fadeInWhatsapp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutWhatsapp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.whatssize {
  z-index: 1;
}

.flags {
  z-index: 1;
}

.WhatsAppButton.open {
  -webkit-animation: fadeInWhatsapp 0.5s ease-in-out forwards;
  -moz-animation: fadeInWhatsapp 0.5s ease-in-out forwards;
  -ms-animation: fadeInWhatsapp 0.5s ease-in-out forwards;
  -o-animation: fadeInWhatsapp 0.5s ease-in-out forwards;
  animation: fadeInWhatsapp 0.5s ease-in-out forwards;
  z-index: 1;
}

.WhatsAppButton.close {
  -webkit-animation: fadeOutWhatsapp 0.5s ease-in-out forwards;
  -moz-animation: fadeOutWhatsapp 0.5s ease-in-out forwards;
  -ms-animation: fadeOutWhatsapp 0.5s ease-in-out forwards;
  -o-animation: fadeOutWhatsapp 0.5s ease-in-out forwards;
  animation: fadeOutWhatsapp 0.5s ease-in-out forwards;
  z-index: 1;
}

.App {
  text-align: center;
  width: 100vw;
}

.Valoriza {
  z-index: 1;
}
.App-logo {
  height: 100vmin;
  width: 100%;
  pointer-events: none;
}

.App-header {
  background-color: #3a3736;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: 100%;
  background-position: center;
}

.App-header2 {
  background-color: blue;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  position: relative;
  height: 100vmin;
  width: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: left;
}

hr {
  z-index: 1;
}

.objetosHome {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  margin-top: 2rem;
}

.objetosHome .logo {
  position: relative;
  top: 35%;
  width: 30vmin;
  height: 30vmin;
  background-color: white;
  border-radius: 150px;
  animation-duration: 4s;
  animation-name: slidein;
  z-index: 1;
}

@keyframes slidein {
  from {
    margin-left: 50%;
    width: 30vmin;
  }

  to {
    margin-left: 0%;
    width: 30vmin;
  }
}

.objetosHome h1 {
  position: relative;
  top: 25%;
  font-size: 50px;
  color: white;
  text-shadow: 0px 0px 5px black;
  margin-top: 0%;
  animation-duration: 4.5s;
  animation-name: slideinFrases;
  z-index: 1;
  -webkit-animation: slideinFrases 4.5s ease-in-out forwards;
  -moz-animation: slideinFrases 4.5s ease-in-out forwards;
  -ms-animation: slideinFrases 4.5s ease-in-out forwards;
  -o-animation: slideinFrases 4.5s ease-in-out forwards;
  animation: slideinFrases 4.5s ease-in-out forwards;
}

.objetoshome h1::after {
  display: none;
}

.objetosHome p {
  position: relative;
  top: 65%;
  font-size: 25px;
  text-align: justify;
  color: white;
  text-shadow: 0px 0px 5px black;
  animation-duration: 4s;
  animation-name: slideinFrases;
}

@keyframes slideinFrases {
  from {
    margin-left: 50%;
    width: 100%;
  }
  to {
    margin-left: 0%;
    width: 100%;
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0.99;
  }
  93% {
    opacity: 0.89;
  }
  95% {
    opacity: 0.79;
  }
  97% {
    opacity: 0.69;
  }
  99% {
    opacity: 0.59;
  }
  99.5% {
    opacity: 0.49;
  }
  99.7% {
    opacity: 0.39;
  }
  99.9% {
    opacity: 0.19;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.botoesInicio {
  position: relative;
  display: flex;
  top: 0%;
  padding-right: 20%;
  padding-left: 20%;
  z-index: 1;
}

/* Style the button and place it in the middle of the container/image */
.botoesInicio .btn {
  width: 110px;
  height: 30px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  /* background-image: url(../../images/logoCirculo.png); */
  background-color: transparent;
  /* background-size: 100px 100px; */
  color: white;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

.div .btn7 {
  position: absolute;
  top: 10%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* background-image: url(../../images/logoCirculo.png); */
  /* background-color: rgb(56, 56, 167); */
  background-repeat: no-repeat;
  /* background-size: 100px 100px; */
  color: black;
  font-size: 15px;
  padding: 40px 29px;
  border: none;
  cursor: pointer;
  border-radius: 45px;
}

.botoesInicio .btn:hover {
  background-color: rgba(18, 37, 238, 0.226);
}

.partDiv {
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 3%;
}

body {
  height: 100%;
  display: grid;
  place-items: center;
  /* align-items: center;
  justify-items: center; */
}

main#carousel {
  grid-row: 1 / 2;
  grid-column: 1 / 10;
  width: 90vw;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 400px;
  --items: 15;
  --middle: 4;
  --position: 4;
  /* pointer-events: none; */
  background-image: url(../../images/logoMarvini.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.testecarrosel {
  transform-style: preserve-3d;
  perspective: 400px;
  --items: 15;
  --middle: 4;
  --position: 4;
}

div.item {
  position: absolute;
  width: 300px;
  height: 370px;
  border-radius: 30px;
  background-color: coral;
  --r: calc(var(--position) - var(--offset));
  --abs: max(calc(var(--r) * -1), var(--r));
  transition: all 0.25s linear;
  transform: rotateY(calc(-10deg * var(--r)))
    translateX(calc(-300px * var(--r)));
  z-index: calc((var(--position) - var(--abs)));
}

div.item:nth-of-type(1) {
  --offset: 1;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 1rem rgb(119, 119, 119);
}
div.item:nth-of-type(2) {
  --offset: 2;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 1rem rgb(119, 119, 119);
}
div.item:nth-of-type(3) {
  --offset: 3;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 0.5rem rgb(119, 119, 119);
}
div.item:nth-of-type(4) {
  --offset: 4;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 1rem rgb(119, 119, 119);
}
div.item:nth-of-type(5) {
  --offset: 5;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 0.5rem rgb(119, 119, 119);
}
div.item:nth-of-type(6) {
  --offset: 6;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 0.5rem rgb(119, 119, 119);
}
div.item:nth-of-type(7) {
  --offset: 7;
  background-color: #d3d3d3;
  background-size: 5px;
  background-repeat: no-repeat;
  box-shadow: 0rem 0 0.5rem rgb(119, 119, 119);
}

.imagemCarrossel {
  display: flex;
  width: 70%;
  height: 50%;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-left: 15%;
  margin-top: 10%;
}

.tituloCarrossel {
  display: inline-flex;
  word-break: break-all;
  margin-top: 10%;
  margin-bottom: 5%;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 1rem;
}

.subtituloCarrossel {
  display: inline-flex;
  word-break: break-all;
  margin-top: 5%;
  margin-bottom: 5%;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 0.875rem;
}

input.inpRadio:nth-of-type(1) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

input.inpRadio:nth-of-type(1):checked ~ main#carousel {
  --position: 1;
}

input.inpRadio:nth-of-type(2) {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

input.inpRadio:nth-of-type(2):checked ~ main#carousel {
  --position: 2;
}

input.inpRadio:nth-of-type(3) {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
}
input.inpRadio:nth-of-type(3):checked ~ main#carousel {
  --position: 3;
}

input.inpRadio:nth-of-type(4) {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
}
input.inpRadio:nth-of-type(4):checked ~ main#carousel {
  --position: 4;
}

input.inpRadio:nth-of-type(5) {
  grid-column: 6 / 7;
  grid-row: 2 / 3;
}
input.inpRadio:nth-of-type(5):checked ~ main#carousel {
  --position: 5;
}

input.inpRadio:nth-of-type(6) {
  grid-column: 7 / 8;
  grid-row: 2 / 3;
}
input.inpRadio:nth-of-type(6):checked ~ main#carousel {
  --position: 6;
}

input.inpRadio:nth-of-type(7) {
  grid-column: 8 / 9;
  grid-row: 2 / 3;
}
input.inpRadio:nth-of-type(7):checked ~ main#carousel {
  --position: 7;
}

.carousel-wrapper {
  height: 300px;
  position: relative;
  width: 200px;
  margin-top: 6%;
  margin-bottom: 5%;
}
.carousel-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 50px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  border-radius: 5%;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 12px;
}

.arrow-prev {
  left: -30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
}

.arrow-next {
  right: -30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.light {
  color: white;
}

@media (max-width: 480px) {
  .arrow,
  .light .arrow {
    background-size: 10px;
    background-position: 10px 50%;
  }
}

/*Select every element*/
[id^="item"] {
  display: none;
}

.item-1 {
  z-index: 2;
  opacity: 1;
  background-color: gray;
  background-size: cover;
}
.item-2 {
  background-color: blue;
  background-size: cover;
}
.item-3 {
  background-color: green;
  background-size: cover;
}

*:target ~ .item-1 {
  opacity: 0;
}

#item-1:target ~ .item-1 {
  opacity: 1;
}

#item-2:target ~ .item-2,
#item-3:target ~ .item-3 {
  z-index: 3;
  opacity: 1;
}

.container7 {
  margin-top: 2%;
  height: 100%;
  width: 95%;
  display: flex;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d1d7d5;
  border-radius: 10px;
  transition: 0.4s ease-out;
  margin-left: 5%;
}

.card:not(:first-child) {
  margin-left: 50px;
}

.card:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.card:hover ~ .card {
  left: 50px;
  transition: 0.4s ease-out;
}

.title {
  color: black;
  font-weight: bold;
  margin-left: 0%;
  margin-top: 10%;
  word-break: break-word;
}

.title2 {
  color: black;
  font-weight: 300;
  margin-left: 0%;
  margin-top: 0%;
  word-break: break-word;
}

.circle {
  margin-top: 60%;
  margin-left: -60%;
}

.stroke {
  stroke: black;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  transition: 0.6s ease-out;
}

svg {
  fill: #cecece;
  width: 50%;
}

.card:hover .stroke {
  stroke-dashoffset: 100;
  transition: 0.6s ease-out;
}

/* ANIMAÇÃO SLIDER HOME */

.slider1 {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* .slider1 figure div {
  float: right;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.slider1 figure img {
  width: 100%;
  background-size: contain;
  /*
  background-position: center;
  background-repeat: no-repeat; */
  float: left;
  background-color: rgba(0, 0, 0, 0.3);
}

.slider1 figure {
  position: relative;
  width: 500%;
  margin: 0;
  animation: animate 70s infinite;
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes animate {
  0% {
    left: 0%;
  }
  10% {
    left: 0%;
  }
  12% {
    left: -100%;
  }
  22% {
    left: -100%;
  }
  24% {
    left: -200%;
  }
  34% {
    left: -200%;
  }
  36% {
    left: -300%;
  }
  46% {
    left: -300%;
  }
  48% {
    left: -400%;
  }
  58% {
    left: -400%;
  }
  60% {
    left: -300%;
  }
  70% {
    left: -300%;
  }
  72% {
    left: -200%;
  }
  82% {
    left: -200%;
  }
  84% {
    left: -100%;
  }
  94% {
    left: -100%;
  }
  96% {
    left: 0%;
  }
}

.imagemDiamond {
  height: 100%;
}

.bannerPt {
  display: flex;
  cursor: pointer;
  margin-top: 20%;
}

.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.productShow-bg {
  background-image: url(../../../images/produto.png);
  height: 137px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.productShow-bg span {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: black 0px 0px 5px;
  font-size: 24px;
}
.product-container {
  display: flex;
  min-width: 100%;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  margin-top: 35px;
}
.box-image-product {
  max-width: 75%;
  min-height: 277px;
  display: flex;
  flex-direction: column;
}

.box-product-info {
  width: 100%;
  height: 159px;
  background-color: #c4c4c457;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 10px;
}
.box-product-info span {
  font-size: 18px;
  color: black;
  font-weight: bold;
}
.desc-div {
  width: 142px;
  height: 35px;
  background-color: #c4c4c457;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.desc-div span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #0c14cc;
}
.desc-box-infos {
  height: 226px;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
}
.desc-box-infos span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}
.div-container-contact {
  width: 100%;
  min-height: 75px;
  background-color: #0c14cc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 83px;
  margin-bottom: 100px;
}
.div-container-contact span {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.contacts-bg {
  background-image: url(../../../images/contato.png);
  height: 137px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contacts-bg span {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: black 0px 0px 5px;
  font-size: 24px;
}

.contacts-container {
  display: flex;
  flex-direction: column;
  gap: 23px;
  align-items: center;
  width: 100%;
}

.representantes-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 307px;
  height: 26px;
  background-color: #4c7de0;
  margin-top: 27px;
}

.representantes-box span {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.representantes-scroll {
  display: flex;
  flex-direction: column;
  gap: 23px;
  max-height: 270px;
  width: 307px;
  overflow-y: auto;
}

.card-representantes {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  min-height: 73px;
  background-color: #d8d8d8;
  overflow-x: auto;
  padding: 10px;
}

.card-representantes span {
  font-size: 12px;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 38px;
}

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.info-card span {
  font-weight: 600;
  font-size: 12px;
  line-height: 13.6px;
  text-align: center;
  color: #555;
}

.info-card:nth-child(3) {
  margin-bottom: 47px;
}

.info-card:nth-child(1) span {
  max-width: 190px;
}

.card-representantes:nth-child(23) {
  border: 1px solid red;
}

.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.bg-company {
  background-image: url(../../../images/empresa.png);
  height: 137px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bg-company span {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: black 0px 0px 5px;
  font-size: 24px;
}

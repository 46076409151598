// NOSSO PRODUTOS

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 20s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

//Styling
.slider {
  margin: 50px auto;
  top: 2%;
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 50%;
  margin-left: 0%;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 2%;
  &::before,
  &::after {
    // @include white-gradient;
    content: "";
    height: 100%;
    position: absolute;
    width: 25%;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slideHome {
    margin-top: 1%;
    height: 200px;
    width: 200px;
    margin-right: 5%;
    float: left;
  }
}

.whatssize {
  width: 80px;
  height: 80px;
  position: relative;
  left: 30px;
}
.barraitens {
  height: 10px;
}

.flags {
  z-index: 1;
}

// COMENTARIOS

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

.cartaComents {
  position: absolute;
  width: 60%;
  height: 400%;
  margin-left: 35%;
  @media (min-width: 768px) and (max-width: 1440px) {
    margin-left: 15%;
    width: 50%;
    height: 300%;
  }
  top: 290px;
  transition: transform 0.4s ease;
  cursor: pointer;
}

.cartaComents figcaption {
  /*comentario*/
  position: absolute;
  bottom: -10%;
  left: 45%;
  width: 130%;
  font-size: 15px;
  margin-left: 3%;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */

    font-size: 15px;
    position: absolute;
    bottom: -5%;
  }
}

.cartaComents .texto {
  /*autor comentario*/
  position: absolute;
  width: fit-content;
  top: 130%;
  left: 70%;
  font-size: 20px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */
    font-size: 15px;

    position: relative;
    top: 70%;
    font-weight: bold;
  }
}

.cartaComents .texto2 {
  /*titulo comentario*/
  position: absolute;
  bottom: 45%;
  left: 97%;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */

    position: absolute;
    width: fit-content;
    left: 300px;
    font-style: italic;
    font-weight: bold;
  }
}

.profileStars {
  position: relative;
  bottom: 10%;
  width: 25%;
  left: 60%;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */
  }
}

.profileImage {
  position: absolute;
  border-color: white;
  border-style: solid;
  border-width: 5px;
  border-radius: 70px;
  bottom: -120%;
  width: 25%;
  left: 100%;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */
    border-color: white;
    position: absolute;
    margin-left: -10%;
    bottom: 2;
  }
}

.containerComents {
  position: relative;
  width: 100%;
  max-width: 700px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  margin-left: -4%;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */
    margin-left: -10%;
  }
}

.cardsComents {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-bottom: 600px;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1170px (large) */
    margin-left: 7%;
  }
}

.cardImage {
  position: absolute;
  width: 150%;
  height: 200%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
}

.seta {
  position: absolute;
  bottom: -8%;
  right: 41.7%;
  height: 200px;
  width: 200px;
  z-index: 999;
}

#item-1:checked ~ .cardsComents #song-3,
#item-2:checked ~ .cardsComents #song-1,
#item-3:checked ~ .cardsComents #song-2 {
  transform: translatex(-40%) scale(0.8);
  opacity: 1;
  z-index: 0;
}

#item-1:checked ~ .cardsComents #song-2,
#item-2:checked ~ .cardsComents #song-3,
#item-3:checked ~ .cardsComents #song-1 {
  transform: translatex(40%) scale(0.8);
  opacity: 1;
  z-index: 0;
}

#item-1:checked ~ .cardsComents #song-1,
#item-2:checked ~ .cardsComents #song-2,
#item-3:checked ~ .cardsComents #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
}

.player {
  background-color: #fff;
  border-radius: 8px;
  min-width: 320px;
  padding: 16px 10px;
  margin-left: 1580px;
}

.upper-part {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 36px;
  overflow: hidden;
}

.song-info {
  width: calc(100% - 32px);
  display: block;
}

.song-info .titleComents {
  color: #403d40;
  font-size: 14px;
  line-height: 26px;
}

.sub-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subtitleComents,
.time {
  font-size: 12px;
  line-height: 10px;
  color: #c6c5c6;
}

.info-area {
  width: 100%;
  position: absolute;
  top: 0;
  left: 15px;
  transition: transform 0.4s ease-in;
}

#item-2:checked ~ .player #test {
  transform: translateY(0);
}

#item-2:checked ~ .player #test {
  transform: translateY(-40px);
}

#item-3:checked ~ .player #test {
  transform: translateY(-80px);
}

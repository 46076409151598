.botoesInicio .btn {
  width: 110px;
  height: 30px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  /* background-image: url(../../images/logoCirculo.png); */
  /* background-color: rgb(56, 56, 167); */
  /* background-size: 100px 100px; */
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

/* .botoesInicio .btn:hover {
  background-color: rgb(0, 13, 133);
} */

.botoesInicio {
  display: flex;
  padding-right: 20%;
  padding-left: 20%;
}

.lancamentos {
  background-color: #ececec;
  margin-top: 2%;
  height: 10%;
}

.lancamentosBtn {
  background: linear-gradient(-45deg, #414dee, #3f30c5, #2438e7, #3f00b5);
  background-size: 800% 400%;
  padding: 1em 2em;
  display: inline-block;
  border: none;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 700;
  color: white;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
}

.lancamentosBtn:hover {
  animation: gradient 3s infinite;
  transform: scale(1.05);
}

.lancamentosBtn:active {
  animation: gradient 3s infinite;
  transform: scale(0.8);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.prod1 {
  position: relative;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: fill;
}

.imagemProduto {
  background-size: cover;
  width: 30%;
  height: 80%;
}

.texto {
  display: block;
}

.vertical-line {
  width: 1px; /* Line width */
  background-color: black; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left;
  position: absolute;
  margin-left: 30%;
  margin-top: 2%;
  /* Causes the line to float to left of content. 
    You can instead use position:absolute or display:inline-block
    if this fits better with your design */
}

/*BOTÃO DE PESQUISA*/
.search {
  width: 115%;
  position: absolute;
  display: flex;
}

.searchTerm {
  position: absolute;
  width: 75%;
  border: 3px solid rgb(248, 248, 248);
  border-right: none;
  background-color: #dbdfddbd;
  font-size: 15px;
  padding: 5px;
  height: 26px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #495550;
}

.searchTerm:focus {
  color: rgb(0, 0, 0);
}

.searchButton {
  position: absolute;
  width: 60px;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(0, 17, 172, 0);
  text-align: center;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  margin-left: 60%;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  position: absolute;
  margin-top: 6%;
  margin-left: 2%;
}

/*BOTÃO COLLAPSIBLE (CLICA PARA ABRIR MAIS OPÇÕES)*/

.filtrarBtn {
  position: absolute;
  margin-left: 8%;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5%;
  min-height: 30px;
  width: 15%;
  height: 5%;
  border: none;
  border-radius: 5px;
  background-color: rgb(209, 210, 221);
  cursor: pointer;
  margin-top: 65%;
  font-size: 18px;
}

.filtrarBtn:hover {
  -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
}

.limparBtn {
  position: absolute;
  margin-left: 8%;
  color: gray;
  font-weight: bold;
  margin-bottom: 0.5%;
  min-height: 30px;
  width: 15%;
  height: 5%;
  border: none;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  margin-top: 69%;
  font-size: 18px;
  border-style: solid;
  border-width: thin;
  border-color: gray;
}

.limparBtn:hover {
  -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
}

.middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.bar {
  width: 10px;
  height: 90px;
  background: #fff;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
  animation: loader 1.2s linear infinite;
}
.bar1 {
  animation-delay: 0.1s;
}
.bar2 {
  animation-delay: 0.2s;
}
.bar3 {
  animation-delay: 0.3s;
}
.bar4 {
  animation-delay: 0.4s;
}
.bar5 {
  animation-delay: 0.5s;
}
.bar6 {
  animation-delay: 0.6s;
}
.bar7 {
  animation-delay: 0.7s;
}
.bar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
  }
  50% {
    transform: scaleY(1);
    background: rgb(0, 17, 172);
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}

/*COLLAPSIBLE*/

.quadrado {
  position: absolute;
  margin-right: 70%;
  width: 20%;
  /* height: 180%; */
  max-height: 100vh;
  margin-left: 5%;
  margin-top: 10%;
  overflow-y: auto;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
input[type="checkbox"] {
  display: none;
}
.wrap-collabsible {
  margin: 1.2rem 0;
}
.lbl-toggle {
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: justify;
  padding: 1rem;
  color: #1b2957;
  background: rgba(0, 17, 172, 0);
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: #3978b3;
}
.lbl-toggle::before {
  content: " ";
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: translateY(-2px);
  transition: transform 0.2s ease-out;
}
.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}
.collapsible-content {
  max-height: 0px;
  overflow: scroll;
  transition: max-height 0.25s ease-in-out;
}
.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 1600px;
}
.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.collapsible-content .content-inner {
  background: rgba(40, 110, 207, 0);

  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 1rem;
}
.collapsible-content p {
  margin-bottom: 0;
}

.carros {
  text-align: center;
  font-weight: bold;
  color: rgb(0, 17, 172);
}

/* body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  position: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

._10MlI {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  color: cadetblue;
  font-size: 2em;
  height: 45px;
  width: 45px;
  margin: 5px;
  padding: 10px;
  background-image: url("https://static3.tcdn.com.br/img/img_prod/673340/reservatorio_agua_radiador_palio_36506_1_20190916135917.jpg");
  background-size: 10rem;
  background-position: center;
  background-position-y: -19.5px;
  background-position-x: -48.5px;
}

._10MlI > svg {
  display: none;
}

._1bpcM {
  background-color: #4caf50;
}

._1bpcM > svg {
  fill: #fff;
  height: 40px;
  width: 40px;
}

._2-mAj {
  display: none;
}

.swiper-button-prev {
  background-image: url("../src/images/seta.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(90deg);
  padding: 14px;
}

.swiper-button-next {
  background-image: url("../src/images/seta.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(-90deg);
  padding: 14px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.bg-products {
  background-image: url(../../../images/produto.png);
  height: 137px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bg-products span {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: black 0px 0px 5px;
  font-size: 24px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 12px;
  margin-inline: auto;
  min-width: 85%;
  max-width: 85%;
  background: rgba(196, 196, 196, 0.34);
  height: 50px;
}

.search-bar input {
  width: 85%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  margin-inline: auto;
  text-align: center;
  padding: 6px 12px;
}

.search-bar input::placeholder {
  text-align: center;
}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 32px;
}

.search-container details summary:nth-child(1) {
  margin-top: 15px;
}

.search-container details {
  width: 85%;
}

.search-container details summary {
  max-width: fit-content;
  min-height: 50px;
  cursor: pointer;
  color: #080c79;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.btn-switch-exclusive {
  width: 200px;
  min-height: 30px;
  background-color: #0c14cc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  border: none;
  outline: none;
  margin: 0 auto;
  padding: 6px 10px;
  cursor: pointer;
}

.btn-reset {
  background-color: #4c7de0;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8em;
  height: 2.2em;
  line-height: 2.5em;
  margin: 0.8rem 1.75rem 0.4rem 0;
  position: relative;
  overflow: hidden;
  transition: color 0.5s;
  z-index: 1;
  border: none;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
}

.search-result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 24px;
}

.search-btn {
  max-width: 40px;
  max-height: 40px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(0, 17, 172, 0);
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.quality-banners {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #e5e5e5;
  height: 200px;
  overflow-x: auto;
}
.banner-mobile {
  height: 147px;
  min-width: 147px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
  border: 1px solid #e5e5e5;
  align-items: center;
  gap: 8px;
  text-align: center;
}
.banner-mobile img {
  max-width: 60px;
  max-height: 50px;
  min-width: 60px;
  min-height: 50px;
  padding-top: 8px;
}
.banner-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 13.64px;
  color: #000;
}
.banner-text {
  font-size: 10px;
  font-weight: 600;
  line-height: 13.64px;
  color: #999;
  margin: auto;
  padding-inline: 4px;
}
.carrossel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.div-title-carroussel {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-title-carroussel span {
  font-size: 25px;
  font-weight: bold;
  color: rgb(27, 41, 87);
  text-align: center;
  max-width: 185px;
  margin-top: 35px;
}
.carrossel-container-prod-exclusive {
  margin-top: 40px;
  margin-bottom: 50px;
}
.div-saiba-mais {
  width: 100%;
  min-height: 75px;
  background-color: #0c14cc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-saiba-mais span {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-next {
  background-size: 60% !important;
}
.swiper-button-prev {
  background-size: 60% !important;
}
.side-span {
  background-image: linear-gradient(
    to right,
    rgb(10, 10, 96),
    rgb(14, 119, 238)
  );
  height: 8px;
  flex: 1;
  margin-top: 35px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -6px !important;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -6px !important;
}

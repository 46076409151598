*{
  margin: 0;
  padding: 0;
}

.banner{
  width: 100%;
  height: 100vh;
  background-color: #222;
  overflow: hidden;
}

.banner .carousel{
  width: 300px;
  margin: 100px auto 0;
  overflow: visible;
}

.carousel img{
  border-radius: 20%;
  -webkit-box-reflect: below 10px linear-gradient(rgba(255,255,255, 0.1), rgba(255,255,255, 0.3));
}


.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.header-bg {
  background-image: url(../../../images/videos.png);
  height: 137px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header-bg span {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: black 0px 0px 5px;
  font-size: 24px;
}

.videos-container {
  display: flex;
  flex-direction: column;
  gap: 23px;
  align-items: center;
  width: 100%;
}

.video-card {
  min-width: 234px;
  min-height: 140px;
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  color: #004dff;
  margin: 18px 0px;
  background: radial-gradient(
    farthest-side,
    currentColor calc(100% - 7px),
    #0000 calc(100% - 6px) 0
  );
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 15px),
    #000 calc(100% - 13px)
  );
  border-radius: 50%;
  animation: spinner-sm4bhi 2s infinite linear;
}

.spinner::before,
.spinner::after {
  content: '';
  grid-area: 1/1;
  background: linear-gradient(currentColor 0 0) center,
    linear-gradient(currentColor 0 0) center;
  background-size: 100% 11px, 11px 100%;
  background-repeat: no-repeat;
}

.spinner::after {
  transform: rotate(45deg);
}

@keyframes spinner-sm4bhi {
  100% {
    transform: rotate(1turn);
  }
}

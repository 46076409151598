.botoesInicio .btn {
  width: 110px;
  height: 30px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  /* background-image: url(../../images/logoCirculo.png); */
  /* background-color: rgb(56, 56, 167); */
  /* background-size: 100px 100px; */
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

/* .botoesInicio .btn:hover {
  background-color: rgb(0, 13, 133);
} */

.divtable {
  margin: 10px 100px 100px 100px;
}

.spantable {
  background-color: #1976d2;
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  text-align: center;
  padding: 20px;
  width: 610px;
  margin: 100px 0px 0px 100px;
}



.icones {
  display: flex;
  background-color: rgb(236, 228, 228);
}

.iconesestado {
  display: flex;
  margin-left: 470px;
}

.tabelabody {
  background-color: rgb(254, 254, 255);
  display: block;
}

.table {
  width: 650px;
  height: 400px;
  display: block;
  overflow-y: scroll;
  text-align: justify;
}

td {
  font-weight: bold;
}

.botoesInicio {
  display: flex;
  padding-right: 20%;
  padding-left: 20%;
}
